import axios from 'axios';
import router from '../router';

const state = {
  products: [],
  labelOptions: [],
  labelConfig: {},
};

const mutations = {
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  setLabelOptions(state, options) {
    state.labelOptions = options;
  },
  setLabelConfig(state, config) {
    state.labelConfig = config;
  },
};

const actions = {
  async fetchProducts({ commit }, { search }) {
    // console.log('Fetching products with search term:', search);
    const userToken = localStorage.getItem('userToken'); 
    if (!userToken) {
        // Redirect to login page if token is not found
        router.push({ name: 'login' });
        return;
    }

    try {
      const response = await axios.get(`https://api.datafangst.dk/api/rackbeatvarer?search=${search}&limit=200`, { headers: { Authorization: `Bearer ${userToken }` }, } );
      console.log(response.data.products);
      commit('SET_PRODUCTS', response.data.products);
    } catch (error) {
      console.error('Error fetching products:', error);
      throw error; // Rethrow the error to handle it in the component
    }
  },
  async fetchLabelOptionsAndConfig({ commit }) {
    const userToken = localStorage.getItem('userToken');
    if (!userToken) {
      // Redirect to login page if token is not found
      router.push({ name: 'login' });
      return;
    }
  
    try {
      const response = await axios.get(`https://api.datafangst.dk/api/labels`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      const { labelOptions, labelConfig } = response.data;
      console.log(response.data);
  
      commit('setLabelOptions', labelOptions);
      commit('setLabelConfig', labelConfig);
    } catch (error) {
      console.error('Error fetching label options and config:', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};